@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize"; 
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2; 
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-weight: bold;
    font-family: $primary-font;
    font-weight: normal;
    font-size: remfont(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}
#bgvideo{
    &-m, &-d{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center;
        z-index: 0;
        text-shadow: 0 0 remfont(10) #000A;
    }

    &-m{
        @media screen and (min-width: 900px){
            display: none;
        }
    }
    &-d{
        @media screen and (max-width: 901px){
            display: none;
        } 
    }
}
#page-wrap{
    min-height: 100vh;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#header{
    padding: remfont(20) 0;
    filter: drop-shadow(0 0 remfont(6) #000C);
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 799px){
            flex-wrap: wrap;
            h1{
                flex-basis: 100%;
                text-align: center;
            }
            
            justify-content: center;
            a{
                margin:remfont(10);
            }
        }
    }
    a{
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
    }
    h1{
        font-size: remfont(46);
        font-weight: 900;
        text-transform: uppercase;
    }
    @media screen and (min-width: 800px){
        a:nth-child(3){
            order: 3;
        }
        a:nth-child(2){
            order: 1;
        }
        h1{
            order: 2;
        }
    }
}

#main{
    text-align: center;
    filter: drop-shadow(0 0 remfont(6) #000C);
    .logo{
        width: remfont(300); 
        max-width: 100%;
        height: auto;
        mix-blend-mode: exclusion;
    }
    .btns a{
        border: 1px solid $white;
        display: inline-block;
        margin: remfont(15);
        font-size: remfont(14);
        text-transform: uppercase;
        padding: 1em;
        width: remfont(225); 
        font-weight: bold;
        text-align: center;
        @include hover{
            color: $black;
            background-color: $white;
        } &.wide{ 
            width: 90%;
            max-width: remfont(400);
        }
    }
}

#footer{
    padding: remfont(20) 0;
    filter: drop-shadow(0 0 remfont(6) #000C);
    text-align: center;
    .container{ 
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 799px){
            flex-direction: column;
            align-items: center;
        }
    }

    .copyright{
        font-size: remfont(8);
        font-family: sans-serif;
        text-transform: uppercase;
        a{
            display: inline-block;
        }
        p{
            width: 100%;
            max-width: remfont(440);
        }
        svg{
            display: block; 
            margin-bottom: remfont(6);
            
            @media screen and (max-width: 799px){
                margin: 0 auto remfont(6);
            }
        }
    }

    .social{
        margin: 1em 0;
        li{
            display: inline-block;
            &+li{
                margin-left: .5em;
            }
        }
    }

    .newsletter{
        min-width: remfont(290);        
    }
    .newsletter form{
        display: inline-block;
        border-bottom: 1px solid $white;
        input{
            font-weight: normal;
            width: remfont(240);
            color: $white;
            margin: 0 0 remfont(10);
            &::placeholder{
                color: $white;
                font-weight: normal;
            }
        }
        button{
            color: $white;
            padding: 0 0 remfont(3);
            border-bottom: 1px solid $white;
            margin: 0 0 remfont(6);
            font-weight: bold;
            @include hover{
                color: $primary-color;
            }
        }
    }
    @media screen and (min-width: 800px){
        .social{
            order: 3;
            text-align: right;
            flex: 1;
        }
        .copyright{
            order: 1;
            text-align: left;
            flex: 1;
        }
        .newsletter{
            
            order: 2;
            flex: 1;
        }
    }
}

.mfp-bg{
    opacity: 1;   
}

.mfp-close{
    right: auto;
    top: remfont(10);
    left: remfont(10);
    font-size: 0;
    width: auto;
    height: auto;
    line-height: 1;
    opacity: 1;
    &::after{
        content: 'CLOSE';
        font-size: remfont(14);
        text-decoration: underline;
        transition: all .3s;
    }
    @include hover{
        &::after{
            color: $primary-color;
        }
    }
}

#music{
    padding: remfont(50) 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item{
        width: remfont(300);
        padding: remfont(10);
        max-width: 100%;
        text-align: center;
        img{
            display: block; 
        }
        p{
            font-size: 1.5rem;
            margin: .3em 0;
        }
        .btn{
            border: 1px solid $white;
            display: inline-block;
            font-size: remfont(14);
            text-transform: uppercase;
            padding: 1em;
            width: remfont(225); 
            font-weight: bold;
            text-align: center;
            @include hover{
                color: $black;
                background-color: $white;
            }
        }
    }
}
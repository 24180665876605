@font-face {
    font-family: 'Neue Plak';
    src: url('../font/NeuePlak-WideBlack.woff2') format('woff2'),
        url('../font/NeuePlak-WideBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak';
    src: url('../font/NeuePlak-ExtendedRegular.woff2') format('woff2'),
        url('../font/NeuePlak-ExtendedRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak';
    src: url('../font/NeuePlak-ExtendedBold.woff2') format('woff2'),
        url('../font/NeuePlak-ExtendedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

